
/************ Custom Font ************/
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Bold.ttf');
	font-weight: 700;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Medium.ttf');
	font-weight: 500;
}
@font-face {
	font-family: Centra;
	src: url('./assets/font/CentraNo2-Book.ttf');
	font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #000000 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 90%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/* Nav bar css   */

/* Navbar CSS */

.profile-picture {
  width: 150px; /* Adjust the width as needed */
  height: 150px; /* Keep it equal to the width for a perfect circle */
  border-radius: 50%;
  overflow: hidden;
}

nav.navbar {
  padding: 10px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: auto;
  display: flex;
  justify-content: flex-start;
  margin-right: auto;
}

.twoELogo {
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.5);
  width: 28%;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

.navbar-collapse {
  display: none;
}

.navbar-collapse.show {
  display: block !important;
}

.navbar-text {
  display: flex;
  align-items: center;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 70px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center; /* Adjusted to center the icons vertically */
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 100%; /* Adjust the width to 100% to fit the container */
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  width: 100%;
  height: 100%;
}

/* Ensure vvd-link is styled correctly to look like a button */
.vvd-link {
  text-decoration: none;
  color: inherit;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

@media (max-width: 768px) {
  /* Apply styles only on screens smaller than 768px (typically mobile devices) */
  nav.navbar {
    background-color: transparent; /* Set initial background to transparent */
  }

  /* Add additional styles to change background color when navbar is open or scrolled */
  nav.navbar.scrolled,
  body.mobile-nav-open nav.navbar {
    background-color: #121212; /* Change background color to black when scrolled or mobile nav is open */
  }

  .twoELogo {
    margin-left: 0; /* Move the logo to the left side */
    width: 36%; /* Restore the original width */
  }

  .navbar-brand {
    display: flex;
    justify-content: flex-start;
    width: auto;
    margin-right: auto; /* Ensure the brand logo stays on the left */
  }

  .navbar-text {
    justify-content: space-between; /* Ensure the social icons and button are on opposite ends */
  }

  .social-icon {
    margin-left: 0; /* Move social icons to the left side */
  }

  .navbar-text button {
    margin-left: auto; /* Move the button to the right side */
  }
}




/************ Banner Css ************/

.aboutme-background {
  background: linear-gradient(90.21deg, rgba(51, 178, 232, 0.2) -5.91%, rgba(74, 47, 189, 0.2) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 10px;
}

.banner {
  margin-top: 0;
  padding: 200px 0 100px 0;
  background-image: url('./assets/img/Background2E.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner .tagline {
  letter-spacing: 0.8px;
  width: 300px;
  height: 300px;
  padding: 8px 10px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 60px;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 60px;
  margin-top: 20px;
  display: block;
}

.banner p {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin-top: 50px;
  padding-left: 30px;
  width: 96%;
  text-align: left;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-right: 45px;
  margin-top: 20px;
  letter-spacing: 0.8px;
  display: inline-block;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  margin-left: 25px;
}

.img1 {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate > .wrap {
  border-right: 0.08em solid #6660;
}

/* Add media queries for smaller screens */
@media (max-width: 768px) {
  .banner h1 {
    font-size: 30px; /* Adjust font size for smaller screens */
    padding: 0 10px; /* Add padding to keep text within bounds */
  }

  .banner p {
    font-size: 16px; /* Adjust font size for smaller screens */
    padding-left: 15px;
    margin-top: 30px;
  }

  .banner button {
    font-size: 18px; /* Adjust font size for smaller screens */
    margin-right: 20px;
    margin-top: 15px;
  }

  .banner button svg {
    font-size: 20px; /* Adjust icon size for smaller screens */
  }
}


/************ Trailers Css ************/
.trailer {
  padding: 0px 0;
  background: #000000;
}

.main-image {
  width: 100%;
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.trailer-bx {
  background: #000000;
  border-radius: 64px;
  text-align: center;
  padding: 100px 50px;
  margin-top: -30px;
}

.trailer h2 {
  font-size: 36px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}

.trailer p {
  color: #B8B8B8;
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 80px;
}

.trailer-slider {
  margin-top: 20px;
}

.trailer-item {
  text-align: center;
}

.trailer-item video {
  width: 100%;
  max-width: 800px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slick-prev,
.slick-next {
  font-size: 36px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: 20px !important;
}

.slick-next {
  right: 20px !important;
}

@media (max-width: 768px) {
  .trailer-bx {
    padding: 50px 20px;
  }

  .trailer h2 {
    font-size: 28px;
  }

  .trailer p {
    font-size: 16px;
    margin-bottom: 40px;
  }

  .trailer-item video {
    width: 100%;
    max-width: 100%;
  }

  .slick-prev,
  .slick-next {
    font-size: 24px;
  }
}







/************ Characters Css ************/
/************ Characters Css ************/
.character {
  padding: 50px 0;
  position: relative;
  background-color: black;
}

.character h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  color: white;
}

.character p {
  color: #B8B8B8;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 30px auto;
  text-align: center;
  width: 80%;
}

.character .nav-pills {
  width: 100%;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.1);
}

.character .nav-pills .nav-item {
  width: 25%;
}
/* In App.css */

.character .nav-pills .nav-link {
  background-color: transparent;
  border: none; /* Remove the border */
  padding: 10px 20px; /* Adjust padding */
  color: #ffffff;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: background-color 0.3s ease-in-out; /* Add transition for smoother hover effect */
  text-align: center;
  z-index: 0;
}

.character .nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, rgba(51, 178, 232, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 0.3s ease-in-out; /* Add transition for smoother hover effect */
}

.character .nav-pills .nav-link:hover::before {
  width: 100%; /* Expand the background gradient on hover */
}

.character .nav-pills .nav-link.active::before {
  width: 100%; /* Expand the background gradient for active link */
}

.character .tab-content {
  padding: 50px 0;
}

.character .character-video {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border: 5px solid transparent;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(157, 202, 249, 0.8);
  transition: box-shadow 0.3s ease-in-out;
}

.character .character-video:hover {
  box-shadow: 0 0 30px rgba(157, 202, 249, 1);
}

.character .character-description {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  margin-top: 20px; /* Add margin to create space between image and text */
}

/* Add media query for smaller screens */
@media (max-width: 768px) {
  .character .character-video {
    max-width: 80%; /* Adjust video size for mobile */
  }

  .character .character-description {
    margin-top: 30px; /* Increase space on mobile */
    padding: 10px; /* Adjust padding for better spacing on mobile */
  }
}

.character .character-description h3 {
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.character .character-description p {
  font-size: 18px;
  color: white;
}



/************ Contact Css ************/
.contact {
  padding: 200px 0 200px 0; /* Added top padding */
  background: linear-gradient(90.21deg, rgba(51, 178, 232, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}

.contact h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}
.contact p {
  font-size: 20px;
  margin-bottom: 40px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: white;
  margin: 0 0 20px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: black;
  background-color: white;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.8);
}

.contact form button::before {
  content: "";
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  opacity: 0;
}

.contact form button:hover::before {
  opacity: 1;
}

.contact-background {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 50px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 1.6;
  text-align: left;
}

.contact-background a {
  color: linear-gradient(90.21deg, rgba(51, 178, 232, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  text-decoration: none;
}

.contact-background a:hover {
  text-decoration: underline;
}

.response-message {
  margin-top: 20px;
  font-size: 16px;
  color: green; /* Customize the color as needed */
}
/* Download.css */

.download {
  margin-top: 0;
  padding: 200px 0 100px 0;
  position: relative;
  background-image: url('./assets/img/Background2E2.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.download p {
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin-bottom: 50px;
  padding-left: 30px;
  width: 96%;
  text-align: left;
}

.game-icon {
  width: 170px;
  height: 170px;
  margin-bottom: 30px;
}

.download h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 50px;
}

.download p {
  font-size: 22px;
  line-height: 1.5;
}

.app-download-link {
  display: inline-block;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}

.app-download-link img {
  width: 100px; /* Adjust the size as needed */
  height: 100px; /* Adjust the size as needed */
  transition: transform 0.3s ease-in-out;
}

.app-download-link:hover img {
  transform: scale(1.2);
}

.app-download-link::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.app-download-link:hover::before {
  opacity: 1;
}
/* Handle Action Css */
.handle-action {
  min-height: 100vh; /* Ensure the background covers the entire viewport height */
  padding: 200px 0; /* Added top padding */
  background: linear-gradient(90.21deg, rgba(51, 178, 232, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}

.handle-action h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}

.handle-action .section-description {
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
  color: white;
}

.handle-action form input,
.handle-action form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: white;
  margin: 0 0 20px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.handle-action form input:focus,
.handle-action form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.handle-action form input::placeholder,
.handle-action form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.handle-action form input:focus::placeholder,
.handle-action form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.handle-action form button {
  font-weight: 700;
  color: black;
  background-color: white;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.handle-action form button span {
  z-index: 1;
  position: relative;
}

.handle-action form button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.8);
}

.handle-action form button::before {
  content: "";
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  opacity: 0;
}

.handle-action form button:hover::before {
  opacity: 1;
}

/* Reset Password Css */
.reset-password {
  padding: 200px 0; /* Added top padding */
  background: linear-gradient(90.21deg, rgba(51, 178, 232, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}

.reset-password h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}

.reset-password .section-description {
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
  color: white;
}

.reset-password form input,
.reset-password form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: white;
  margin: 0 0 20px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.reset-password form input:focus,
.reset-password form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.reset-password form input::placeholder,
.reset-password form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.reset-password form input:focus::placeholder,
.reset-password form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.reset-password form button {
  font-weight: 700;
  color: black;
  background-color: white;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.reset-password form button span {
  z-index: 1;
  position: relative;
}

.reset-password form button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.8);
}

.reset-password form button::before {
  content: "";
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  opacity: 0;
}

.reset-password form button:hover::before {
  opacity: 1;
}

.reset-password-background {
  background-color: rgba(0, 0, 0, 0.15);
  padding: 50px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 18px;
  line-height: 1.6;
  text-align: left;
}

.reset-password-background a {
  background: linear-gradient(90.21deg, rgba(51, 178, 232, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
  text-decoration: none;
}

.reset-password-background a:hover {
  text-decoration: underline;
}

.response-message {
  margin-top: 20px;
  font-size: 16px;
  color: green; /* Customize the color as needed */
}


/********/

/* User Info and Actions Css */
.user-info-actions {
  min-height: 100vh; /* Ensure the background covers the entire viewport height */
  padding: 200px 0; /* Added top padding */
  background: linear-gradient(90.21deg, rgba(51, 178, 232, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
}

.user-info-actions h2 {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: white;
  margin-bottom: 30px;
}

.user-info-actions h4 {
  margin-top: 80px;
  margin-bottom: 30px;
}

.user-info-actions .section-description {
  font-size: 20px;
  margin-bottom: 40px;
  text-align: center;
  color: white;
}

.user-info-actions form input,
.user-info-actions form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: white;
  margin: 0 0 20px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.user-info-actions form input:focus,
.user-info-actions form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.user-info-actions form input::placeholder,
.user-info-actions form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: white;
}

.user-info-actions form input:focus::placeholder,
.user-info-actions form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.user-info-actions form button {
  font-weight: 700;
  color: black;
  background-color: white;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 20px;
  position: relative;
  transition: 0.3s ease-in-out;
  margin-bottom: 20px; /* Add space between buttons */
}

.user-info-actions form button span {
  z-index: 1;
  position: relative;
}

.user-info-actions form button:hover {
  color: white;
  background-color: rgba(255, 255, 255, 0.8);
}

.user-info-actions form button::before {
  content: "";
  background: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  border-radius: 20px;
  transition: 0.3s ease-in-out;
  opacity: 0;
}

.user-info-actions form button:hover::before {
  opacity: 1;
}

.user-info-actions .btn-container {
  display: flex;
  justify-content: center;
  gap: 50px; /* Adjust the gap between the buttons */
  margin-top: 50px; /* Add space between buttons and text */
}

.user-info-actions ul {
  margin-top: 20px; /* Add space between text and list */
  padding-left: 20px; /* Ensure the list is aligned to the left */
  color: white; /* Set the list color to white for visibility */
  text-align: left; /* Ensure the text is aligned to the left */
}

.user-info-actions ul li {
  margin-bottom: 10px; /* Add space between list items */
}

.user-info-actions p {
  margin-bottom: 40px; /* Add space between text and fields */
}

.user-info-actions .delete-container {
  margin-top: 50px; /* Add more space above this div */
  align-items: center;
}

.user-info-actions .important-text {
  color: rgb(254, 58, 58);
  font-weight: bold;
  text-align: left; /* Ensure the text is aligned to the left */
}

.user-info-actions .login-form .form-group {
  margin-bottom: 40px; /* Add space between form groups */
}


/*Default CSS */


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
